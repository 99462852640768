import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FiSun, FiMoon, FiBell, FiHeart } from 'react-icons/fi';
import Tooltip from '@mui/material/Tooltip';
import { useCart } from './contexts/CartContext';
import { useDarkMode } from './DarkModeContext';
import { useUser } from './contexts/UserContext';
import { motion } from 'framer-motion';
import { Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import axios from 'axios';

// Custom hook for loading images with fallback
const useImage = (src, fallback) => {
  const [imgSrc, setImgSrc] = useState(src);

  const onError = () => setImgSrc(fallback);

  return [imgSrc, onError];
};

const Navbar = ({ notifications, addNotification, removeNotification }) => {
  const { cart, clearCart, addItemToCart, removeItemFromCart } = useCart();
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const { user, logout } = useUser();
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [isCartDropdownOpen, setIsCartDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [cartDetails, setCartDetails] = useState([]);
  const notificationRef = useRef();
  const token = localStorage.getItem('token');

  const cartItemCount = cart.reduce((count, item) => count + item.quantity, 0);

  const navigation = [
    { name: "Shop", href: "/store" },
    { name: "Activities", href: "/Activities" },
    { name: "Diabetes", href: "/Diabetes" },
    { name: "Testimonial", href: "/testimonial" },
    { name: "Contact us", href: "/Contactus" },
    { name: "Support us", href: "/Supportus" },
    { name: "History", href: "/History" },
    { name: "Indived", href: "https://Indived.co.uk" },
  ];

  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setIsNotificationsOpen(false);
    }
  };

  useEffect(() => {
    if (isNotificationsOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isNotificationsOpen]);

  useEffect(() => {
    const fetchDetails = async () => {
      const details = await Promise.all(
        cart.map(async (item) => {
          try {
            if (item.productId) {
              const response = await axios.get(`${process.env.REACT_APP_API_URL}/products/${item.productId}`, {
                headers: { Authorization: `Bearer ${token}` }
              });
              return { ...item, product: response.data };
            } else if (item.activitiesId) {
              const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/activities/${item.activitiesId}`, {
                headers: { Authorization: `Bearer ${token}` }
              });
              return { ...item, activities: response.data };
            }
          } catch (error) {
            console.error('Failed to fetch item details:', error);
            return { ...item, error: 'Failed to fetch details' };
          }
          return item;
        })
      );
      setCartDetails(details);
    };

    if (cart.length > 0) {
      fetchDetails();
    } else {
      setCartDetails([]);
    }
  }, [cart, token]);

  // Helper function to get item image URL
  const getItemImageUrl = (item) => {
    const imageUrl = item.product?.imageUrl || item.activities?.imageUrl;
    return imageUrl ? `${process.env.REACT_APP_API_URL}/${imageUrl}` : '/default-image.jpg';
  };

  return (
    <nav className={`flex items-center relative sticky top-0 z-50 shadow-lg ${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-800'}`}>
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          <Link to="/" className="flex-shrink-0">
            <motion.img src="/Logo.png" alt="INDIVED Logo" className="h-12 w-auto" whileHover={{ scale: 1.1 }} />
          </Link>

          <div className="hidden md:flex items-center space-x-6">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className="px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white transition duration-150"
              >
                {item.name}
              </Link>
            ))}
          </div>

          <div className="flex items-center space-x-4">
            <Tooltip title="Toggle Dark Mode" arrow>
              <button onClick={toggleDarkMode} className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none" aria-label="Toggle Dark Mode">
                {isDarkMode ? <FiSun className="h-6 w-6" /> : <FiMoon className="h-6 w-6" />}
              </button>
            </Tooltip>
            {user ? (
              <UserProfileMenu
                isProfileOpen={isProfileOpen}
                setIsProfileOpen={setIsProfileOpen}
                logout={logout}
                user={user}
              />
            ) : (
              <GuestMenu />
            )}
            <CartDropdown
              isCartDropdownOpen={isCartDropdownOpen}
              setIsCartDropdownOpen={setIsCartDropdownOpen}
              cart={cartDetails}
              cartItemCount={cartItemCount}
              getItemImageUrl={getItemImageUrl}
              addItemToCart={addItemToCart}
              removeItemFromCart={removeItemFromCart}
              clearCart={clearCart}
            />
            <Tooltip title="Notifications" arrow>
              <div className="relative" ref={notificationRef}>
                <button 
                  onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
                  className="p-1 rounded-full text-gray-400 hover:text-white focus:outline-none relative" aria-label="Notifications">
                  <FiBell className="h-6 w-6" />
                  {notifications.length > 0 && (
                    <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-600"></span>
                  )}
                </button>
                <Transition
                  show={isNotificationsOpen}
                  enter="transition ease-out duration-200"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {notifications.map((notification, index) => (
                      <div key={index} className="px-4 py-2 text-sm text-gray-700 flex justify-between items-center">
                        <span>{notification}</span>
                        <button onClick={() => removeNotification(index)} className="text-red-500 hover:text-red-700 focus:outline-none">
                          <XIcon className="h-4 w-4" />
                        </button>
                      </div>
                    ))}
                  </div>
                </Transition>
              </div>
            </Tooltip>
            <Link
              to="/Supportus#donate"
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-6 rounded-full flex items-center space-x-2 transition duration-150"
            >
              <FiHeart className="h-5 w-5" />
              <span>Donate</span>
            </Link>
          </div>

          <MobileMenuButton
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
          />
        </div>
      </div>

      <Transition show={isMobileMenuOpen} enter="transition ease-out duration-100 transform" enterFrom="opacity-0 scale-95" enterTo="opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
        <div className={`md:hidden border-t ${isDarkMode ? 'bg-gray-900 border-gray-700' : 'bg-white border-gray-200'}`} id="mobile-menu">
          {navigation.map((item) => (
            <Link key={item.name} to={item.href} className={`block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-700 hover:text-white transition duration-150 ${isDarkMode ? 'text-white' : 'text-gray-700'}`}>
              {item.name}
            </Link>
          ))}
          <Link
            to="/Supportus#donate"
            className="block px-3 py-2 mt-2 rounded-md text-base font-medium text-white bg-red-500 hover:bg-red-700 transition duration-150"
          >
            Donate
          </Link>
        </div>
      </Transition>
    </nav>
  );
};

const UserProfileMenu = ({ isProfileOpen, setIsProfileOpen, logout, user }) => {
  return (
    <div className="relative">
      <button onClick={() => setIsProfileOpen(!isProfileOpen)} className="text-gray-800 hover:text-white focus:outline-none" aria-label="Open user menu">
        <span className="sr-only">Open user menu</span>
        <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100 text-center font-medium text-gray-800">{user.username[0]}</span>
      </button>
      <Transition
        show={isProfileOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Link to="/profile" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Profile</Link>
          <Link to="/orders" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Orders</Link>
          <button onClick={logout} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Logout</button>
        </div>
      </Transition>
    </div>
  );
};

const GuestMenu = () => (
  <div className="flex space-x-4">
    <Link to="/login" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-150">Login</Link>
    <Link to="/register" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded transition duration-150">Register</Link>
  </div>
);

const CartItem = ({ item, getItemImageUrl, handleAddItemToCart, handleRemoveItemFromCart }) => {
  const [imgSrc, onError] = useImage(getItemImageUrl(item), '/default-image.jpg');

  return (
    <div key={item.id} className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center justify-between">
      <img src={imgSrc} alt={item.product?.name || item.activities?.name} className="h-10 w-10 object-cover rounded-full mr-4" onError={onError} />
      <span>{item.product?.name || item.activities?.name}</span>
      <div className="flex items-center">
        <button onClick={() => handleRemoveItemFromCart(item.id)} className="px-2 py-1 text-gray-800 hover:bg-gray-300 rounded transition duration-150">-</button>
        <span className="px-2">{item.quantity}</span>
        <button onClick={() => handleAddItemToCart(item)} className="px-2 py-1 text-gray-800 hover:bg-gray-300 rounded transition duration-150">+</button>
      </div>
    </div>
  );
};

const CartDropdown = ({ isCartDropdownOpen, setIsCartDropdownOpen, cart, cartItemCount, getItemImageUrl, addItemToCart, removeItemFromCart, clearCart }) => {
  return (
    <div
      className="relative"
      onMouseEnter={() => setIsCartDropdownOpen(true)}
      onMouseLeave={() => setIsCartDropdownOpen(false)}
    >
      <Tooltip title="View Cart" arrow>
        <button className="text-gray-800 hover:text-white focus:outline-none" aria-label="View Cart">
          <span className="sr-only">View Cart</span>
          <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-gray-100 text-center font-medium text-gray-800 relative">
            🛒 {cartItemCount}
            {cartItemCount > 0 && (
              <span className="absolute top-0 right-0 h-2 w-2 rounded-full bg-red-600"></span>
            )}
          </span>
        </button>
      </Tooltip>
      <Transition
        show={isCartDropdownOpen}
        enter="transition ease-out duration-200"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          {cart.length > 0 ? (
            cart.map((item) => (
              <CartItem
                key={item.id}
                item={item}
                getItemImageUrl={getItemImageUrl}
                handleAddItemToCart={addItemToCart}
                handleRemoveItemFromCart={removeItemFromCart}
              />
            ))
          ) : (
            <div className="px-4 py-2 text-sm text-gray-700">Your cart is empty</div>
          )}
          <Link to="/ViewCart" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
            View Cart
          </Link>
          <button onClick={clearCart} className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
            Clear Cart
          </button>
        </div>
      </Transition>
    </div>
  );
};

const MobileMenuButton = ({ isMobileMenuOpen, setIsMobileMenuOpen }) => (
  <div className="-mr-2 flex md:hidden">
    <button onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)} className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none" aria-label="Toggle Mobile Menu">
      <span className="sr-only">Open main menu</span>
      {isMobileMenuOpen ? <XIcon className="h-6 w-6" /> : <MenuIcon className="h-6 w-6" />}
    </button>
  </div>
);

export default Navbar;
