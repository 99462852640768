import axios from 'axios';
import axiosRetry from 'axios-retry';

// Create an axios instance with a base URL
const api = axios.create({
  baseURL: 'https://diabetesselfhelpgroup.co.uk', // Use domain URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Configure axios-retry
axiosRetry(api, {
  retries: 3,
  retryCondition: (error) => {
    return error.response && error.response.status === 429;
  }, // Retry only on rate limit errors
  retryDelay: (retryCount) => {
    console.warn(`Retrying request... Attempt #${retryCount}`);
    return axiosRetry.exponentialDelay(retryCount);
  },
});

// Response interceptor for logging and additional handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 429) {
      console.error('Rate limit exceeded. Retrying...');
    } else {
      console.error('Request failed:', error);
    }
    return Promise.reject(error);
  }
);

// Example API functions
export const getUserProfile = (userId) => api.get(`/users/${userId}`);
export const updateUserProfile = (userId, data) => api.put(`/users/${userId}`, data);
export const createStripeCustomer = (userId) => api.post(`/users/${userId}/create-stripe-customer`);

export const fetchProducts = async () => {
  try {
    const response = await api.get('/products');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch products:', error);
    throw error;
  }
};

export const fetchActivities = async () => {
  try {
    const response = await api.get('/api/activities');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch activities:', error);
    throw error;
  }
};

export const fetchBlogPosts = async () => {
  try {
    const response = await axios.get('https://newsapi.org/v2/everything?q=ayurveda+ayurvedic&apiKey=aad579573dbb44b980a1a74673e300ca');
    return response.data.articles;
  } catch (error) {
    console.error('Failed to fetch blog posts:', error);
    throw error;
  }
};

export const fetchNews = async () => {
  try {
    const response = await api.get('/news');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch news:', error);
    throw error;
  }
};

export const fetchEvents = async () => {
  try {
    const response = await api.get('/events');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch events:', error);
    throw error;
  }
};

export const fetchResearchArticles = async () => {
  try {
    const response = await api.get('/research');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch research articles:', error);
    throw error;
  }
};

export const fetchPersonalStories = async () => {
  try {
    const response = await api.get('/stories');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch personal stories:', error);
    throw error;
  }
};

export const deleteEvent = async (eventId) => {
  try {
    const response = await api.delete(`/events/${eventId}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete event:', error);
    throw error;
  }
};

export const updateEvent = async (eventId, data) => {
  try {
    const response = await api.put(`/events/${eventId}`, data);
    return response.data;
  } catch (error) {
    console.error('Failed to update event:', error);
    throw error;
  }
};

export const fetchNewsletters = async () => {
  try {
    const response = await api.get('/newsletters');
    return response.data;
  } catch (error) {
    console.error('Failed to fetch newsletters:', error);
    throw error;
  }
};

export const createNewsletter = async (data) => {
  try {
    const response = await api.post('/newsletters', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to create newsletter:', error);
    throw error;
  }
};

export const deleteNewsletter = async (id) => {
  try {
    const response = await api.delete(`/newsletters/${id}`);
    return response.data;
  } catch (error) {
    console.error('Failed to delete newsletter:', error);
    throw error;
  }
};

export const updateNewsletter = async (id, data) => {
  try {
    const response = await api.put(`/newsletters/${id}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Failed to update newsletter:', error);
    throw error;
  }
};

export const signUpNewsletter = async (data) => {
  try {
    const response = await api.post('/newsletters/signup', data);
    return response.data;
  } catch (error) {
    console.error('Failed to sign up for newsletter:', error);
    throw error;
  }
};

export const sendNewsletter = async (id) => {
  try {
    const response = await api.post(`/newsletters/${id}/send`);
    return response.data;
  } catch (error) {
    console.error('Failed to send newsletter:', error);
    throw error;
  }
};

export const uploadPdf = async (formData) => {
  try {
    const response = await api.post('/newsletters/upload-pdf', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error uploading PDF:', error);
    throw error;
  }
};

export default api;
